<template>
  <div>
    <base-section id="service-provider-account">
      <v-container>
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <v-card class="rounded-xl mx-auto" max-width="1200" flat>
              <v-col cols="12" md="12">
                <base-info-card :title="$t('AccountSP.mainTitle')" />
              </v-col>
            </v-card>
            <v-card class="rounded-xl mx-auto" max-width="1200">
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-card-account-details-outline"
                title-tag="AccountSP.mainTitle"
              />
              <v-tabs show-arrows>
                <v-tab id="AccountSP_tabCpyAccount" class="ml-2">
                  {{ $t("AccountSP.tabCpyAccount") }}
                </v-tab>
                <v-divider class="mx-4" inset vertical />
                <v-tab id="AccountSP_tabAccount">
                  {{ $t("AccountSP.tabAccount") }}
                </v-tab>
                <v-tab-item class="mt-3">
                  <!------------  MANAGEMENT OF THE 1st TAB: COMPANY ACCOUNT DETAILS   ----------------------->
                  <v-card>
                    <v-card-text>
                      <v-form>
                        <v-row no-gutters>
                          <v-col cols="12" sm="6">
                            <v-col cols="12" sm="12">
                              <kdw-text-field
                                id="identity_companyname"
                                v-model="myServiceProvider.namecpy"
                                :label="
                                  $t('identity.companyname') + $t('meta.star')
                                "
                                prepend-icon="mdi-account-box"
                                clearable
                              />
                            </v-col>
                            <v-col cols="12" sm="12">
                              <kdw-text-field
                                id="identity_siret"
                                v-model="myServiceProvider.siret"
                                :label="$t('identity.siret') + $t('meta.star')"
                                prepend-icon="mdi-account-box"
                                clearable
                              />
                            </v-col>
                            <!-- We decided to remove this field as not obvious for user
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <kdw-text-field
                                id="identity_rcs"
                                v-model="myServiceProvider.rcs"
                                :label="$t('identity.rcs') + $t('meta.star')"
                                prepend-icon="mdi-bank"
                                clearable
                              />
                            </v-col>
                            -->
                            <v-col cols="12" sm="12">
                              <v-autocomplete
                                id="IDlegalStatus"
                                v-model="myServiceProvider.legalStatus"
                                :items="legalStatusListFR1"
                                item-text="value"
                                item-value="id"
                                auto-select-first
                                dense
                                prepend-icon="mdi-balance"
                                :label="
                                  $t('identity.legalStatus') + $t('meta.star')
                                "
                              />
                            </v-col>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <kdw-picture-selector
                              v-model="myServiceProvider.logo"
                              nature="Logo"
                              :display="trickUpdatePicture"
                            />
                          </v-col>
                        </v-row>
                        <kdw-address
                          v-model="myServiceProvider.addresscpy"
                          :display="trickUpdateAddress"
                          id-additional="cpy"
                        />
                        <v-row no-gutters>
                          <v-col cols="12" sm="4">
                            <v-col>
                              <kdw-vue-tel-input
                                v-model="myServiceProvider.phonecpy"
                                select-id="IDcpyphone"
                                @internationalPhoneNumber="
                                  internationalPhoneNumberFctCpy($event)
                                "
                              />
                            </v-col>
                          </v-col>
                          <v-col cols="12" sm="8">
                            <v-col>
                              <kdw-text-field-email
                                id="IDcpymail"
                                v-model="myServiceProvider.emailcpy"
                              />
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-col>
                              <v-checkbox
                                id="AccountSP_siretVerified"
                                v-model="myServiceProvider.siretVerified"
                              >
                                <template #label>
                                  <p
                                    class="text-justify"
                                    v-html="
                                      $t('AccountSP.siretVerified') +
                                      $t('meta.star')
                                    "
                                  />
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-col>
                              <v-checkbox
                                id="IDcpyconsent"
                                v-model="myServiceProvider.consentcpy"
                              >
                                <template #label>
                                  <kdw-legal-sentence />
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mr-1 mb-1" justify="end">
                          <kdw-btn @click="update">
                            {{ $t("button.validateQuestion") }}
                          </kdw-btn>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <!------------  MANAGEMENT OF THE 2nd TAB: RESPONSIBLE ACCOUNT DETAILS   ----------------------->
                  <v-card>
                    <v-card-text>
                      <v-form>
                        <v-row>
                          <v-col cols="12" sm="5">
                            <kdw-text-field
                              id="IDfirstname"
                              v-model="myServiceProvider.firstname"
                              :label="
                                $t('identity.firstname') + $t('meta.star')
                              "
                              prepend-icon="mdi-account-circle"
                              clearable
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <kdw-text-field
                              id="IDlastname"
                              v-model="myServiceProvider.lastname"
                              :label="$t('identity.lastname') + $t('meta.star')"
                              prepend-icon="mdi-account-circle"
                              clearable
                            />
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-btn
                                  dense
                                  plain
                                  outlined
                                  color="jean"
                                  min-width="144"
                                  class="font-weight-bold ml-7"
                                  rounded
                                  v-on="on"
                                  @click="copyCpyPerso"
                                >
                                  {{ $t("button.copyCpy") }}
                                  <v-icon> mdi-content-copy </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("button.copyCpy") }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <kdw-address
                          v-model="myServiceProvider.address"
                          :display="trickUpdateAddress"
                        />
                        <v-row>
                          <v-col cols="12" sm="4">
                            <kdw-vue-tel-input
                              v-model="myServiceProvider.phone"
                              @internationalPhoneNumber="
                                internationalPhoneNumberFct($event)
                              "
                            />
                          </v-col>
                          <v-col cols="12" sm="8">
                            <kdw-text-field-email
                              id="IDmail"
                              v-model="myServiceProvider.email"
                            />
                          </v-col>
                        </v-row>
                        <v-row justify="end" class="mr-1 mb-1">
                          <kdw-btn @click="update">
                            {{ $t("button.validateQuestion") }}
                          </kdw-btn>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { updateServiceProvider as updateServiceProviderMutation } from "@/graphql/mutations";
import { getServiceProvider } from "@/graphql/queries";
import { API, Logger } from "aws-amplify";
import { getUserSub } from "@/services/User";
import { pictureUpdateS3 } from "@/helpers/GenericFunctionsS3Download";

const logger = new Logger("ServiceProviderAccount");

@Component({ name: "ServiceProviderMyAccount" })
class ServiceProviderMyAccount extends Vue {
  trickUpdatePicture = 0;
  myServiceProvider = {
    id: "",
    firstname: "",
    lastname: "",
    address: {
      address1: "",
      address2: "",
      postcode: "",
      cityId: "",
      countryId: "",
    },
    phone: "",
    phoneInt: "",
    email: "",
    consent: false,
    namecpy: "",
    logo: {
      originalName: "",
      name: "",
      image: null,
    },
    siret: "",
    siretVerified: false,
    addresscpy: {
      address1: "",
      address2: "",
      postcode: "",
      cityId: "",
      countryId: "",
    },
    phonecpy: "",
    phonecpyInt: "",
    emailcpy: "",
    consentcpy: false,
    rcs: "",
    legalStatus: "",
  };

  trickUpdateAddress = 0;

  rules = [
    (value) =>
      !value || value.size < 2000000 || this.$i18n.t("AccountSP.sizeLogo"),
  ];

  legalStatusListFR = ["", "", "", "", "", ""];

  legalStatusListFR1 = [
    {
      id: "identity.cpyFRIndividual",
      value: this.$i18n.t("identity.cpyFRIndividual"),
    },
    {
      id: "identity.cpyFRAutoEntrepreneur",
      value: this.$i18n.t("identity.cpyFRAutoEntrepreneur"),
    },
    { id: "identity.cpyFRSARL", value: this.$i18n.t("identity.cpyFRSARL") },
    { id: "identity.cpyFRSAS", value: this.$i18n.t("identity.cpyFRSAS") },
    { id: "identity.cpyFRSA", value: this.$i18n.t("identity.cpyFRSA") },
    { id: "identity.cpyFRSNC", value: this.$i18n.t("identity.cpyFRSNC") },
  ];

  internationalPhoneNumberFct(internationPhoneNumber) {
    // console.log('intPhoneNumber:', internationPhoneNumber)
    this.myServiceProvider.phoneInt = internationPhoneNumber;
  }

  internationalPhoneNumberFctCpy(internationPhoneNumber) {
    // console.log('intPhoneNumber:', internationPhoneNumber)
    this.myServiceProvider.phonecpyInt = internationPhoneNumber;
  }

  cleanSPNullData() {
    if (this.myServiceProvider.email === null)
      this.myServiceProvider.email = "";
  }

  // click on the submit button
  update() {
    this.cleanSPNullData();
    // console.log('myServiceProvider:')
    // console.log(this.myServiceProvider)
    const validResult = this.updateValidation();
    if (validResult !== "") {
      this.$root.kdwsnackbar.alert(validResult);
      return;
    }
    if (
      !this.myServiceProvider.id ||
      !this.myServiceProvider.namecpy ||
      !this.myServiceProvider.emailcpy
    )
      return;
    this.updateServiceProviderData(this.myServiceProvider);
  }

  updateValidation() {
    // Company validation - Moral person
    if (!this.myServiceProvider.namecpy)
      return "AccountSP.alertCpyNameNotSpecified";
    if (!this.myServiceProvider.siret) return "AccountSP.alertCpySiret";
    // if (!this.myServiceProvider.rcs) return 'AccountSP.alertCpyRcs'
    if (!this.myServiceProvider.legalStatus)
      return "AccountSP.alertCpyLegalStatus";
    if (!this.myServiceProvider.addresscpy.address1)
      return "AccountSP.alertCpyAddress1";
    if (!this.myServiceProvider.addresscpy.postcode)
      return "AccountSP.alertCpyPostcode";
    if (!this.myServiceProvider.addresscpy.cityId)
      return "AccountSP.alertCpyserviceProviderPostcodecitycpyId";
    if (!this.myServiceProvider.addresscpy.countryId)
      return "AccountSP.alertCpyserviceProviderCountrycpyId";
    if (!this.myServiceProvider.phonecpy) return "AccountSP.alertCpyPhone";
    if (!this.myServiceProvider.phonecpyInt) return "AccountSP.alertCpyPhone";
    if (!this.myServiceProvider.emailcpy) return "AccountSP.alertCpyEmail";
    // if (this.myServiceProvider.consentcpy === false) return 'AccountSP.alertCpyConsent'
    // if (this.myServiceProvider.siretVerified === false) return 'AccountSP.alertCpySiretVerified'
    // Company validation - Responsible person - We agreed to limit the number of check
    if (!this.myServiceProvider.firstname) return "AccountSP.alertFirsname";
    if (!this.myServiceProvider.lastname) return "AccountSP.alertLastname";
    return "";
  }

  // Before loading the form but also called during the form exit!!!!
  created() {
    this.loadServiceProviderData(this.myServiceProvider);
  }

  async copyCpyPerso() {
    // console.log('this.myServiceProvider before copy:')
    // console.log(this.myServiceProvider)
    if (this.myServiceProvider.addresscpy.address1 !== "") {
      this.myServiceProvider.address.address1 =
        this.myServiceProvider.addresscpy.address1;
    }
    if (this.myServiceProvider.addresscpy.address2 !== "") {
      this.myServiceProvider.address.address2 =
        this.myServiceProvider.addresscpy.address2;
    }
    if (this.myServiceProvider.addresscpy.postcode !== "") {
      this.myServiceProvider.address.postcode =
        this.myServiceProvider.addresscpy.postcode;
    }
    if (
      this.myServiceProvider.addresscpy.cityId !== null &&
      this.myServiceProvider.addresscpy.cityId !== ""
    ) {
      this.myServiceProvider.address.cityId =
        this.myServiceProvider.addresscpy.cityId;
    }
    if (
      this.myServiceProvider.addresscpy.countryId !== null &&
      this.myServiceProvider.addresscpy.countryId !== null
    ) {
      this.myServiceProvider.address.countryId =
        this.myServiceProvider.addresscpy.countryId;
    }
    if (this.myServiceProvider.phonecpyInt !== "") {
      this.myServiceProvider.phone = this.myServiceProvider.phonecpy;
      this.myServiceProvider.phoneInt = this.myServiceProvider.phonecpyInt;
    }
    if (this.myServiceProvider.emailcpy !== "") {
      this.myServiceProvider.email = this.myServiceProvider.emailcpy;
    }
    this.trickUpdateAddress++;
    this.$root.kdwsnackbar.alert("AccountSP.dataCopied");
  }

  async loadServiceProviderData(myServiceProvider) {
    // console.log('loadServiceProviderData')
    // const user = await Auth.currentAuthenticatedUser()
    try {
      // console.log('sub SP: ' + user.attributes.sub)
      const apiDataEndUser = await API.graphql({
        query: getServiceProvider,
        variables: { id: await getUserSub() },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // console.log(apiDataEndUser)
      const ServiceProvider = apiDataEndUser.data.getServiceProvider;
      // console.log('ServiceProvider data:')
      // console.log(ServiceProvider)
      if (ServiceProvider.length === 0) {
        this.$root.kdwsnackbar.alert("AccountSP.alertSPNoFound");
        return;
      }
      // change the data by reference (can not pass the object)
      // ------------ Load the Company responsible data
      myServiceProvider.firstname = ServiceProvider.firstname;
      myServiceProvider.lastname = ServiceProvider.lastname;
      myServiceProvider.address.address1 = ServiceProvider.address1;
      myServiceProvider.address.address2 = ServiceProvider.address2;
      myServiceProvider.address.postcode = ServiceProvider.postcode;
      if (ServiceProvider.postcodecity === null) {
        myServiceProvider.address.cityId = "";
      } else {
        myServiceProvider.address.cityId = ServiceProvider.postcodecity.id;
      }
      if (ServiceProvider.country === null) {
        myServiceProvider.address.countryId = "";
      } else {
        myServiceProvider.address.countryId = ServiceProvider.country.id;
      }
      myServiceProvider.phone = ServiceProvider.phone;
      myServiceProvider.phoneInt = ServiceProvider.phone;
      myServiceProvider.email = ServiceProvider.email;
      myServiceProvider.consent = ServiceProvider.consent;
      myServiceProvider.id = ServiceProvider.id;
      myServiceProvider.rcs = ServiceProvider.rcs;
      myServiceProvider.legalStatus = ServiceProvider.legalStatus;
      // ------------ Load the Company data
      myServiceProvider.namecpy = ServiceProvider.namecpy;
      myServiceProvider.siret = ServiceProvider.siret;
      myServiceProvider.siretVerified = ServiceProvider.siretVerified;
      myServiceProvider.addresscpy.address1 = ServiceProvider.addresscpy1;
      myServiceProvider.addresscpy.address2 = ServiceProvider.addresscpy2;
      myServiceProvider.addresscpy.postcode = ServiceProvider.postcodecpy;
      if (ServiceProvider.postcodecitycpy === null) {
        myServiceProvider.addresscpy.cityId = "";
      } else {
        myServiceProvider.addresscpy.cityId =
          ServiceProvider.postcodecitycpy.id;
      }
      if (ServiceProvider.countrycpy === null) {
        myServiceProvider.addresscpy.countryId = "";
      } else {
        myServiceProvider.addresscpy.countryId = ServiceProvider.countrycpy.id;
      }
      myServiceProvider.logo.originalName = ServiceProvider.logocpy;
      myServiceProvider.logo.name = ServiceProvider.logocpy;
      myServiceProvider.phonecpy = ServiceProvider.phonecpy;
      myServiceProvider.phonecpyInt = ServiceProvider.phonecpy;
      myServiceProvider.emailcpy = ServiceProvider.emailcpy;
      myServiceProvider.consentcpy = ServiceProvider.consentcpy;
      this.trickUpdateAddress++;
      this.trickUpdatePicture++;
      // ------------ Load the invoice data
      /*
        myServiceProvider.nameinv = ServiceProvider.nameinv
        myServiceProvider.siretinv = ServiceProvider.siretinv
        myServiceProvider.siretVerifiedinv = ServiceProvider.siretVerifiedinv
        myServiceProvider.addressinv1 = ServiceProvider.addressinv1
        myServiceProvider.addressinv2 = ServiceProvider.addressinv2
        myServiceProvider.postcodeinv = ServiceProvider.postcodeinv
        if (ServiceProvider.postcodecityinv === null) {
          myServiceProvider.serviceProviderPostcodecityinvId = ''
        } else {
          myServiceProvider.serviceProviderPostcodecityinvId = ServiceProvider.postcodecityinv.id
        }
        if (ServiceProvider.countryinv === null) {
          myServiceProvider.serviceProviderCountryinvId = ''
        } else {
          myServiceProvider.serviceProviderCountryinvId = ServiceProvider.countryinv.id
        }
        myServiceProvider.phoneinv = ServiceProvider.phoneinv
        myServiceProvider.emailinv = ServiceProvider.emailinv
        if (ServiceProvider.postcodeinv.length === 5) {
          // console.log(townscpy)
          await fetchTownfromPostalCode(myServiceProvider.postcodeinv, townscpy)
        }
        */
      // console.log('myServiceProvider:')
      // console.log(myServiceProvider)
    } catch (err) {
      logger.error(err);
      this.$root.kdwsnackbar.alert("AccountSP.alertSPLoadDataIssue", -1);
      // alert(this.$i18n.t('AccountSP.alertSPLoadDataIssue'))
    }
  }

  async updateServiceProviderData(objectServiceProviderToUpdate) {
    try {
      const ServiceProviderToUpdate = {
        id: objectServiceProviderToUpdate.id,
        firstname: objectServiceProviderToUpdate.firstname,
        lastname: objectServiceProviderToUpdate.lastname,
        address1: objectServiceProviderToUpdate.address.address1,
        address2: objectServiceProviderToUpdate.address.address2,
        postcode: objectServiceProviderToUpdate.address.postcode,
        serviceProviderPostcodecityId:
          objectServiceProviderToUpdate.address.cityId,
        serviceProviderCountryId:
          objectServiceProviderToUpdate.address.countryId,
        phone: objectServiceProviderToUpdate.phoneInt,
        email: objectServiceProviderToUpdate.email,
        consent: objectServiceProviderToUpdate.consent,
        namecpy: objectServiceProviderToUpdate.namecpy,
        siret: objectServiceProviderToUpdate.siret,
        siretVerified: objectServiceProviderToUpdate.siretVerified,
        addresscpy1: objectServiceProviderToUpdate.addresscpy.address1,
        addresscpy2: objectServiceProviderToUpdate.addresscpy.address2,
        postcodecpy: objectServiceProviderToUpdate.addresscpy.postcode,
        serviceProviderPostcodecitycpyId:
          objectServiceProviderToUpdate.addresscpy.cityId,
        serviceProviderCountrycpyId:
          objectServiceProviderToUpdate.addresscpy.countryId,
        phonecpy: objectServiceProviderToUpdate.phonecpyInt,
        emailcpy: objectServiceProviderToUpdate.emailcpy,
        consentcpy: objectServiceProviderToUpdate.consentcpy,
        rcs: objectServiceProviderToUpdate.rcs,
        legalStatus: objectServiceProviderToUpdate.legalStatus,
      };
      const updateFileResult = await pictureUpdateS3(
        this.myServiceProvider.logo,
      );
      // console.log('updateFileResult:')
      // console.log(updateFileResult)
      if (updateFileResult === "error") {
        this.$root.kdwsnackbar.alert("AccountSP.alertSPFailedUpdate", -1);
        return;
      }
      if (updateFileResult === "true") {
        ServiceProviderToUpdate.logocpy =
          objectServiceProviderToUpdate.logo.name;
      }
      // console.log('ServiceProviderToUpdate:')
      // console.log(ServiceProviderToUpdate)
      // We update the data:
      await API.graphql({
        query: updateServiceProviderMutation,
        variables: { input: ServiceProviderToUpdate },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      this.$root.kdwsnackbar.alert("AccountSP.alertSPSuccessUpdate");
    } catch (err) {
      logger.error(err);
      // console.log('Error happened')
      this.$root.kdwsnackbar.alert("AccountSP.alertSPFailedUpdate", -1);
      // alert(this.$i18n.t('AccountSP.alertSPFailedUpdate'))
    }
  }
}

export default ServiceProviderMyAccount;
</script>
